<template lang="pug">
include ../../../configs/template
div.w-100.text-left
  v-card-title(v-if="!$route.params?.documentID")
    v-tabs(v-model="activeTab" color="primary" grow)
      v-tab(v-for="tab in tabs" :key="tab.id" @click="tab.action()") {{$t(tab.label)}}
  v-divider
  div(v-if="!activeTab && !$route.params?.documentID").row.align-end
    div.col-12.mt-2
      +select-validation('auth_agent', 'representativesList', 'agent', 'langRepresentative', '["required"]')(return-object)
    div.col-sm-12.col-md-6
      +field('body.blank_strict_report', '"strictBlank"')(type="number")
    div.col-sm-12.col-md-6
      +date-picker-validation('body.date_issued', 'dateIssue', 'dateIssueObject', '["required", "minValue", "maxValue"]')(
        :max="new Date().toISOString()" min="1900-01-01")
  div(v-else).row
    div.col-sm-12.col-md-6
      +field-validation('body.other_number', 'number', '["required"]')
    div.col-sm-12.col-md-6
      +date-picker-validation('body.date_issued', 'dateIssue', 'dateIssueObject', '["required", "minValue", "maxValue"]')(
        :max="new Date().toISOString()" min="1900-01-01")
    div.col-sm-12.col-md-6
      +select-validation('body.branch_office', 'branchOfficesList', 'affiliate', 'labelName', '["required"]')
    div.col-sm-12.col-md-6
      +field('body.blank_strict_report', '"strictBlank"')(type="number")
    div.col-12
      label {{ $t('agent') }} - {{ $t('nameUK') }}
        span.required-field-star *
    div(v-if="!$route.params?.documentID").col-12
      div.row
        div.col-sm-12.col-md-4
          +field-validation('last_name_ukr', 'lastName', '["required"]')
        div.col-sm-12.col-md-4
          +field-validation('first_name_ukr', 'name', '["alphaUA", "required"]')
        div.col-sm-12.col-md-4
          +field-validation('middle_name_ukr', 'middleName', '["alphaUA"]')
    div(v-else).col-12
      +field-validation('body.auth_agent_ukr', 'fullNameUa', '["required"]')
    div.col-12
      label {{ $t('agent') }} - {{ $t('nameEN') }}
        span.required-field-star *
    div(v-if="!$route.params?.documentID").col-12
      div.row
        div.col-sm-12.col-md-6
          +field-validation('last_name_eng', 'lastName', '["alphaEN", "required"]')
        div.col-sm-12.col-md-6
          +field-validation('first_name_eng', 'name', '["alphaEN", "required"]')
    div(v-else).col-12
      +field-validation('body.auth_agent_eng', 'fullNameEn', '["required"]')
    div.col-12
      +field('body.waybill_number', '"wayBillNumber"')(type="number")
    div.col-12
      FileDropZone(ref="mediaContent" v-if="checkAccess('serviceRecordBook', 'add_file')")
  div.row
    div.col-12.text-center
      v-btn(@click="saveServiceRecordBook" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { ServiceRecordBookForm } from '@/mixins/validationRules'
import { SUCCESS_CODE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'

const initBody = () => (
  {
    new_record: true,
    other_number: null,
    branch_office: null,
    date_issued: null,
    auth_agent_ukr: null,
    blank_strict_report: null,
    auth_agent_eng: null,
    waybill_number: null
  }
)

export default {
  name: 'FormCreateEditServiceRecordBook',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      isLoading: false,
      activeTab: null,
      auth_agent: null,
      last_name_ukr: null,
      first_name_ukr: null,
      middle_name_ukr: null,
      last_name_eng: null,
      first_name_eng: null,
      tabs: [
        { id: 1, label: 'addNew', checkAccess: () => checkAccess('serviceRecordBook', 'createNewDoc'), action: () => (this.body.new_record = true) },
        { id: 2, label: 'addExist', checkAccess: () => checkAccess('serviceRecordBook', 'createExistDoc'), action: () => (this.body.new_record = false) }
      ],
      body: initBody()
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      langRepresentative: state => state.main.lang === 'en' ? 'FIO_eng' : 'FIO_ukr',
      branchOfficesList: state => state.directory.affiliate,
      representativesList: state => state.directory.agents
    }),
    dateIssueObject () {
      return this.body.date_issued ? new Date(this.body.date_issued) : null
    }
  },
  validations () { return ServiceRecordBookForm(this) },
  mounted () {
    if (!checkAccess('serviceRecordBook', 'createNewDoc') || !this.$route.params?.documentID) this.new_record = false
    if (this.$route.params?.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.sailorDocument[key]))
    }
  },
  methods: {
    ...mapActions(['setRecordBook', 'updateRecordBookById']),
    async saveServiceRecordBook () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true
      if (this.body.new_record) {
        this.body.auth_agent_ukr = this.auth_agent.FIO_ukr
        this.body.auth_agent_eng = this.auth_agent.FIO_eng
      } else if (!this.$route.params?.documentID) {
        this.body.auth_agent_ukr = `${this.last_name_ukr} ${this.first_name_ukr} ${this.middle_name_ukr}`
        this.body.auth_agent_eng = `${this.last_name_eng} ${this.first_name_eng}`
      }
      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) }
      }
      if (!this.body.new_record) {
        data.media = {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'record_book_doc'
        }
      }
      const response = await this[`${this.$route.params.documentID ? 'updateRecordBookById' : 'setRecordBook'}`](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$parent.isViewAddSlot = false
        this.body = initBody()
        this.$v.$reset()
      }
      this.isLoading = false
    }
  }
}
</script>
